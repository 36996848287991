<template>
  <div>
    <vs-popup
      class="pdfClass"
      fullscreen
      :title="$t('Summary')"
      :active.sync="pdfEnable"
    >
      <vs-row vs-type="flex" vs-justify="center">
        <div
          ref="contentPDF"
          style="background-color: #fff; width: 760px; height: 1080px"
        >
          <!-- LOGO KISMI -->
          <div style="background-color: #000; width: 760px; height: 65px">
            <div class="flex justify-between p-3">
              <vs-row vs-type="flex" vs-align="center">
                <p class="mr-2" style="color: white; font-size: 20px">
                  {{ $t("Order") }} {{ $t("No_Number") }}:
                </p>
                <p style="color: white; font-size: 20px; font-weight: 600">
                  {{ orders.id }}
                </p>
              </vs-row>

              <img
                style="width: 200px"
                src="@/assets/images/logo/logo_white.png"
                alt=""
              />
            </div>
          </div>
          <!-- SIPARIS DETAYLARI -->
          <div class="flex justify-between pr-6 pl-6 pt-4 pb-4">
            <vs-col>
              <vs-row>
                <p class="orderDetailTitle">
                  {{ $t("Machine") }} {{ $t("Type") }}:
                </p>
                <p class="orderDetailValue">{{ orders.machine_type }}</p>
              </vs-row>
              <vs-row>
                <p class="orderDetailTitle">
                  {{ $t("Total") }} {{ $t("Different_Product") }}:
                </p>
                <p class="orderDetailValue">{{ products.length }}</p>
              </vs-row>
              <vs-row>
                <p class="orderDetailTitle">
                  {{ $t("Total") }} {{ $t("Number_of_Product") }}:
                </p>
                <p class="orderDetailValue">{{ totalProductUnit }}</p>
              </vs-row>
              <vs-row>
                <p class="orderDetailTitle">
                  {{ $t("Total") }} {{ $t("Resin") }}:
                </p>
                <p class="orderDetailValue">
                  {{ parseFloat(orders.total_resin).toFixed(2) }} {{ $t("Gr") }}
                </p>
              </vs-row>
            </vs-col>
            <vs-col>
              <vs-row style="justify-content: flex-end">
                <p class="orderDetailTitle">{{ $t("Order Date Time") }}:</p>
              </vs-row>
              <vs-row style="justify-content: flex-end">
                <p class="orderDetailValue">
                  {{ getRelativeTime(orders.created) }}
                </p>
              </vs-row>
            </vs-col>
          </div>
          <div class="pr-6 pl-6">
            <div>
              <vs-row style="background-color: #000">
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 120px"
                >
                  {{ $t("Images") }}
                </p>
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 110px"
                >
                  {{ $t("Title") }}
                </p>
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 140px"
                >
                  {{ $t("Quantity") }}
                </p>
                <p
                  class="orderDetailValue mt-2"
                  style="color: white; width: 86px"
                >
                  {{ $t("Size") }}
                </p>
                <p
                  class="orderDetailValue mt-2"
                  style="color: white; width: 72px"
                >
                  {{ $t("Resin") }} {{ $t("Gr") }}
                </p>
                <p
                  class="orderDetailValue mt-2 text-right"
                  style="color: white; width: 148px"
                >
                  {{ $t("Total") }} {{ $t("Resin") }} {{ $t("Gr") }}
                </p>
              </vs-row>
              <vs-row>
                <div style="width: 360px"></div>
                <p class="orderDetailValue mt-2 mr-4">16</p>
                <p class="orderDetailValue mt-2 mr-4">18</p>
                <p class="orderDetailValue mt-2 mr-4">20</p>
                <p class="orderDetailValue mt-2">22</p>
              </vs-row>
              <vs-row
                v-for="product in products.slice(0, 10)"
                :key="product.title"
                vs-type="flex"
                vs-align="center"
                style="background-color: #f5f5f5"
                class="mt-2"
              >
                <img
                  style="width: 70px; height: 70px"
                  :src="product.product.thumbnail"
                  alt=""
                />
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 190px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue ml-4 mt-6">
                    {{ product.product.title }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 80px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue mt-6 text-center">
                    {{ product.quantity }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 150px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[0] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[1] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[2] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6">
                    {{ product.selectedSize[3] }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 100px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue mt-6 text-center">
                    {{ product.product.resin_gr.toFixed(2) }} {{ $t("Gr") }}
                  </p>
                </div>
                <div style="width: 128px; height: 70px">
                  <p class="orderDetailValue mt-6 text-center">
                    {{
                      (product.product.resin_gr * product.quantity).toFixed(2)
                    }}
                    {{ $t("Gr") }}
                  </p>
                </div>
              </vs-row>
            </div>
          </div>
        </div>
        <vs-divider></vs-divider>

        <div
          ref="contentPDF2"
          style="background-color: #fff; width: 760px; height: 1080px"
        >
          <div class="pr-6 pl-6">
            <div>
              <vs-row style="background-color: #000">
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 120px"
                >
                  {{ $t("Images") }}
                </p>
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 110px"
                >
                  {{ $t("Title") }}
                </p>
                <p
                  class="orderDetailValue m-2"
                  style="color: white; width: 140px"
                >
                  {{ $t("Quantity") }}
                </p>
                <p
                  class="orderDetailValue mt-2"
                  style="color: white; width: 86px"
                >
                  {{ $t("Size") }}
                </p>
                <p
                  class="orderDetailValue mt-2"
                  style="color: white; width: 72px"
                >
                  {{ $t("Resin") }} {{ $t("Gr") }}
                </p>
                <p
                  class="orderDetailValue mt-2 text-right"
                  style="color: white; width: 148px"
                >
                  {{ $t("Total") }} {{ $t("Resin") }} {{ $t("Gr") }}
                </p>
              </vs-row>
              <vs-row>
                <div style="width: 360px"></div>
                <p class="orderDetailValue mt-2 mr-4">16</p>
                <p class="orderDetailValue mt-2 mr-4">18</p>
                <p class="orderDetailValue mt-2 mr-4">20</p>
                <p class="orderDetailValue mt-2">22</p>
              </vs-row>
              <vs-row
                v-for="product in products2"
                :key="product.title"
                vs-type="flex"
                vs-align="center"
                style="background-color: #f5f5f5"
                class="mt-2"
              >
                <img
                  style="width: 70px; height: 70px"
                  :src="product.product.thumbnail"
                  alt=""
                />
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 190px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue ml-4 mt-6">
                    {{ product.product.title }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 80px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue mt-6 text-center">
                    {{ product.quantity }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 150px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[0] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[1] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6 mr-4">
                    {{ product.selectedSize[2] }}
                  </p>
                  <p class="orderDetailValue text-center mt-6">
                    {{ product.selectedSize[3] }}
                  </p>
                </div>
                <div
                  style="
                    border-right: 1px solid #e2e2e2;
                    width: 100px;
                    height: 70px;
                  "
                >
                  <p class="orderDetailValue mt-6 text-center">
                    {{ product.product.resin_gr.toFixed(2) }} {{ $t("Gr") }}
                  </p>
                </div>
                <div style="width: 128px; height: 70px">
                  <p class="orderDetailValue mt-6 text-center">
                    {{
                      (product.product.resin_gr * product.quantity).toFixed(2)
                    }}
                    {{ $t("Gr") }}
                  </p>
                </div>
              </vs-row>
            </div>
          </div>
        </div>
      </vs-row>
    </vs-popup>

    <vx-card>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center">
        <vs-row
          class="mb-2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="6"
            vs-xs="12"
          >
            <p class="orderDetailTitles">
              {{ $t("Order") }} {{ $t("No_Number") }}: {{ orders.id }}
            </p>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="6"
            vs-xs="12"
          >
            <p class="orderDetailTitles">
              {{ $t("Total") }} {{ $t("Different_Product") }}:
              {{ products.length }}
            </p>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="6"
            vs-xs="12"
          >
            <p class="orderDetailTitles">
              {{ $t("Total") }} {{ $t("Resin") }} :
              {{ parseFloat(orders.total_resin).toFixed(2) }} {{ $t("Gr") }}
            </p>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="6"
            vs-xs="12"
          >
            <vs-row vs-type="flex" vs-justify="between" vs-align="center">
              <p class="orderDetailTitles mr-6">
                {{ $t("Order") }} {{ $t("Status") }}:
              </p>
              <vs-chip
                class="chipFont"
                :color="getOrderStatusColor(orderStatus)"
                >{{ getOrderStatus(orderStatus) }}</vs-chip
              >
            </vs-row>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-row
        class="mb-4"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="6"
          vs-xs="12"
        >
          <p class="orderDetailTitles">
            {{ $t("Created") }} : {{ getRelativeTime(orders.created) }}
          </p>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="6"
          vs-xs="12"
        >
          <p class="orderDetailTitles">
            {{ $t("Total") }} {{ $t("Number_of_Product") }}:
            {{ totalProductUnit }}
          </p>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="6"
          vs-xs="12"
        >
          <p class="orderDetailTitles">
            {{ $t("Total") }} {{ $t("Credit") }} : ${{ totalPrice }}
          </p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="6"
          vs-xs="12"
        >
          <vs-button
            class="orderDetailTitles"
            v-if="orderStatus == 'W'"
            size="small"
            :disabled="checkOutEnable"
            @click="payOrderCredit"
            >{{ $t("Checkout") }}
          </vs-button>
          <vs-button
            v-else
            :disabled="exportDisable"
            class="orderDetailTitles"
            size="small"
            @click="preparingImages"
          >
            {{ $t("Export") }}
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-divider class="mb-4" />

      <div ref="content">
        <vs-row
          class="mb-4"
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
        >
          <vs-button
            class="orderDetailTitles mb-2"
            size="small"
            v-if="havePlates"
            :disabled="repeatOrderButton"
            @click="
              repeatOrder();
              repeatOrderButton = true;
            "
            >{{ $t("Add_Order_To_Cart") }}
          </vs-button>
          <vs-button
            class="orderDetailTitles mb-2"
            v-if="havePlates"
            size="small"
            @click="goToPlate()"
            >{{ $t("Go_To_Plates") }}</vs-button
          >
        </vs-row>
        <vs-row>
          <vs-col
            class="p-1"
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            vs-lg="1"
            vs-sm="2"
            vs-xs="4"
            :key="orderItem.id"
            v-for="orderItem in products"
          >
            <orderItem @loadedImage="handleImageLoad" :orderItem="orderItem">
            </orderItem>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

import OrderItem from "./components/OrderDetailCard.vue";

export default {
  data() {
    return {
      pdfEnable: false,
      pdfImages: [],
      pdfPageSize: 0,
      delayDoImage: 500,
      exportDisable: false,
      checkOutEnable: false,
      repeatOrderButton: false,
      sizesOforder: "",

      //Image Loading
      loadedImage: 0,
      ImageCount: 0,

      //About Orders
      orderNo: null,
      havePlates: false,
      orderStatus: "",
      orders: [],
      products: [],
      products2: [],
      totalPrice: 0,
      realOrders: [],
    };
  },
  components: {
    orderItem: OrderItem,
  },
  computed: {
    totalProductUnit() {
      var totalUnit = 0;

      this.products.forEach((element) => {
        totalUnit += element.quantity;
      });

      return totalUnit;
    },
    getCredit() {
      return this.$store.getters.getDesignMarketCredit;
    },
  },

  methods: {
    preparingImages() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.pdfEnable = true;
      var productCountStart = 10;
      var productCount = 12;
      this.pdfPageSize = Math.floor(this.products.length / 12) + 1;
      for (let index = 0; index < this.pdfPageSize; index++) {
        setTimeout(() => {
          this.pdfImages.push(new Image());
          let that = this;
          if (index == 0) {
            domtoimage.toPng(this.$refs.contentPDF).then(function (dataUrl) {
              that.pdfImages[index].src = dataUrl;
            });
          } else {
            this.products2 = this.products.slice(
              productCountStart + productCount * (index - 1),
              productCount * index + productCountStart
            );
            domtoimage.toPng(this.$refs.contentPDF2).then(function (dataUrl) {
              that.pdfImages[index].src = dataUrl;
            });
          }
          if (index + 1 == this.pdfPageSize) {
            this.exportPDF();
          }
        }, 500);
      }
    },
    exportPDF() {
      var that = this;
      setTimeout(() => {
        try {
          const doc = new jsPDF({
            orientation: "portrait",
          });
          var img = new Image();
          img.src = this.pdfImages[0].src;
          doc.addImage(img, "JPEG", 5, 5);
          doc.setFontSize(10);
          doc.text("Page: " + 1, 100, 285);

          for (let index = 1; index < this.pdfPageSize; index++) {
            doc.addPage();
            var img = new Image();
            img.src = this.pdfImages[index].src;
            doc.addImage(img, "JPEG", 5, 5);
            doc.setFontSize(10);
            doc.text("Page: " + (index + 1), 100, 285);
          }
          const filename = this.orderNo + ".pdf";
          doc.save(filename);
          this.pdfDownloaded();
        } catch (error) {
          that.exportPDF();
        }
      }, 0);
    },
    pdfDownloaded() {
      this.pdfEnable = false;
      this.$vs.loading.close();
    },

    goToPlate() {
      var params = {
        order: this.orderNo,
      };

      this.$router
        .push({
          name: "plates",
          query: params,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - HH:mm");
    },
    repeatOrder() {
      this.products.forEach((element) => {
        if (element.product.size != "General") {
          element.product.size = element.product.size.split(",");
        }

        var differentElement = true;

        var shopItems = this.$store.getters["designMarket/getShopItems"];

        shopItems.forEach((element2) => {
          if (element2.id === element.product.id) {
            this.$store.dispatch("designMarket/updateItemQuantitySameItem", {
              quantity: element.quantity,
              id: element2.id,
              size: element.product.size,
            });
            differentElement = false;
          }
        });

        if (differentElement) {
          var item = {
            id: element.product.id,
            title: element.product.title,
            price: element.product.price,
            size: element.product.size,
            selectedSize: [0, 0, 0, 0, 0],
            quantity: element.quantity,
            image: element.product.image,
            thumbnail: element.product.thumbnail,
            resin_gr: element.product.resin_gr,
          };
          this.$store.dispatch("designMarket/addItemInShop", item);
        }
      });

      this.$vs.notify({
        title: this.$t("Products_added"),
        text: this.products.length + " " + this.$t("Design_Message_3"),
        color: "success",
      });
    },
    payOrderCredit() {
      this.checkOutEnable = true;
      Api.post(
        API.BASEURL + API.ORDERS + this.orderNo + "/buy/",
        this.handlePayOrderCredit
      );
    },
    handlePayOrderCredit(status) {
      if (status == 204) {
        this.checkOutEnable = false;

        this.$vs.notify({
          color: "warning",
          title: this.$t("Design_Message_13"),
          text: this.$t("Design_Message_14"),
        });
      } else if (status == 201) {
        this.orderStatus = "N";
        this.$vs.notify({
          color: "success",
          title: this.$t("Successful"),
          text: this.$t("Design_Message_12"),
        });

        if (this.getCredit - this.totalPrice < 0) {
          this.updateCredit();
        } else {
          this.$store.dispatch(
            "updateDesignMarketCredit",
            (this.getCredit - this.totalPrice).toFixed(2)
          );
        }
      }
    },
    updateCredit() {
      Api.get(API.BASEURL + API.ME, this.handleUpdateCredit);
    },
    handleUpdateCredit(status, data) {
      if (status == 200) {
        this.$store.dispatch("setUserInfo", data);
      }
    },

    handleImageLoad() {
      this.loadedImage += 1;
      if (this.loadedImage == this.ImageCount) {
        this.$vs.loading.close();
      }
    },
    combineSameSizeProducts(orderItems) {
      var newOrderItemList = [];
      newOrderItemList.push(orderItems[0]);
      newOrderItemList[0].selectedSize = [0, 0, 0, 0, 0];
      if (orderItems[0].size == "16") {
        newOrderItemList[0].selectedSize[0] = orderItems[0].quantity;
      } else if (orderItems[0].size == "18") {
        newOrderItemList[0].selectedSize[1] = orderItems[0].quantity;
      } else if (orderItems[0].size == "20") {
        newOrderItemList[0].selectedSize[2] = orderItems[0].quantity;
      } else if (orderItems[0].size == "22") {
        newOrderItemList[0].selectedSize[3] = orderItems[0].quantity;
      } else if (orderItems[0].size == "24") {
        newOrderItemList[0].selectedSize[4] = orderItems[0].quantity;
      }

      if (orderItems[0].size == "General") {
        newOrderItemList[0].selectedSize[0] = 0;
      }

      // index 1 den başlıyor, ilk item daha önce eklendi
      for (let index = 1; index < orderItems.length; index++) {
        const indexNewItemList = newOrderItemList.findIndex(
          (i) => i.product.id == orderItems[index].product.id
        );
        if (indexNewItemList == -1) {
          newOrderItemList.push(orderItems[index]);
          newOrderItemList[newOrderItemList.length - 1].selectedSize = [
            0,
            0,
            0,
            0,
            0,
          ];
          if (orderItems[index].size == "16") {
            newOrderItemList[newOrderItemList.length - 1].selectedSize[0] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "18") {
            newOrderItemList[newOrderItemList.length - 1].selectedSize[1] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "20") {
            newOrderItemList[newOrderItemList.length - 1].selectedSize[2] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "22") {
            newOrderItemList[newOrderItemList.length - 1].selectedSize[3] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "24") {
            newOrderItemList[newOrderItemList.length - 1].selectedSize[4] =
              orderItems[index].quantity;
          }
        } else {
          newOrderItemList[indexNewItemList].size +=
            "," + orderItems[index].size;

          newOrderItemList[indexNewItemList].quantity +=
            orderItems[index].quantity;

          if (orderItems[index].size == "16") {
            newOrderItemList[indexNewItemList].selectedSize[0] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "18") {
            newOrderItemList[indexNewItemList].selectedSize[1] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "20") {
            newOrderItemList[indexNewItemList].selectedSize[2] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "22") {
            newOrderItemList[indexNewItemList].selectedSize[3] =
              orderItems[index].quantity;
          } else if (orderItems[index].size == "24") {
            newOrderItemList[indexNewItemList].selectedSize[4] =
              orderItems[index].quantity;
          }
        }
        if (orderItems[newOrderItemList.length - 1].size == "General") {
          newOrderItemList[newOrderItemList.length - 1].selectedSize[0] = 0;
        }
      }

      return newOrderItemList;
    },
    getOrderStatus(status) {
      if (status == "P") return this.$t("Processing");
      if (status == "R") return this.$t("Ready");
      if (status == "C") return this.$t("Cancelled");
      if (status == "W") return this.$t("Waiting_Payment");
      return this.$t("New");
    },
    getOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
    handleGetOrderDetailRequest(status, data) {
      if (status == 200) {
        this.realOrders = data.order_items;
        this.orders = data;
        this.orderStatus = data.order_status;
        this.products = this.combineSameSizeProducts(data.order_items);
        this.ImageCount = this.products.length;
        this.totalPrice = data.total_price.toFixed(2);

        this.havePlates = data.has_plate;
      } else if (status == 0) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
          color: "danger",
        });
      } else if (status == 404) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Design_Message_24") + this.orderNo,
          color: "danger",
        });
        this.$router.push("/design_orders");
      }
    },

    getOrders() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.ORDERS + this.orderNo + "/",
        this.handleGetOrderDetailRequest
      );
    },
  },
  created: function () {
    if (this.$route.query.no != null) {
      this.orderNo = this.$route.query.no;
    } else {
      this.$router.push("/design_orders");
    }

    this.getOrders();
  },
};
</script>

<style lang="scss">
.con-vs-popup.fullscreen .vs-popup {
  width: auto !important;
  height: auto !important;
  .popupImage {
    width: 100%;
    height: auto;
  }
}
</style>

<style lang="scss" scoped>
.vx-card .vx-card__collapsible-content img {
  display: block;
  width: 100%;
}
.productTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chipFont {
  font-size: 0.9rem;
  font-weight: 800;
}
.orderDetailTitles {
  font-size: 1rem;
  font-weight: 600;
}
.itemPhoto {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.detailProduct {
  border: 1px solid #efefef;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.orderDetailTitle {
  color: #000;
}
.orderDetailValue {
  font-weight: 600;
  color: #000;
}
</style>
