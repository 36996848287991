<template>
  <div class="card">
    <vx-card class="mb-4">
      <vs-row class="mb-2" vs-type="flex" vs-justify="center" vs-align="center">
        <p class="productId">{{ title }}</p>
      </vs-row>
      <vs-row class="mb-2" vs-type="flex" vs-justify="center" vs-align="center">
        <p class="resinGr mr-1">{{ $t("Resin_Weight") }}:</p>
        <p class="resinGr">{{ resinGr.toFixed(3) }} {{ $t("Gr") }}</p>
      </vs-row>
      <vs-row class="mb-2" vs-type="flex" vs-justify="center" vs-align="center">
        <p class="resinGr">{{ $t("Product_Weight") }}</p>
      </vs-row>
      <vs-row
        class="mb-2 p-0"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p class="resinGr">
            (14K)
            <br />
            {{ parseFloat((resinGr * goldWeightMultipier.gold14K).toFixed(1)) }}
            {{ $t("Gr") }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p class="resinGr">
            (18K)
            <br />
            {{ parseFloat((resinGr * goldWeightMultipier.gold18K).toFixed(1)) }}
            {{ $t("Gr") }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <p class="resinGr">
            (21K)
            <br />
            {{ parseFloat((resinGr * goldWeightMultipier.gold21K).toFixed(1)) }}
            {{ $t("Gr") }}
          </p>
        </vs-col>
      </vs-row>

      <vs-row
        v-if="stoneCount != 0"
        class="mb-2 p-0 mt-8"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <p class="resinGr">{{ $t("Stone_Qty") }} :</p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <p class="resinGr">{{ stoneCount }}</p>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: ["title", "resinGr", "stoneCount"],
  computed: {
    goldWeightMultipier() {
      return this.$store.getters["designMarket/getGoldWeightMultipier"];
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
}
.productId {
  font-weight: 900;
  font-size: 1.6em;
}

.resinGr {
  font-weight: 800;
  font-size: 1em;
}
</style>
