<template>
  <div class="detailProduct">
    <vs-popup fullscreen :title="popupTitle" :active.sync="popupActive">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col
          class="p-1"
          vs-type="flex"
          vs-justify="center"
          vs-lg="8"
          vs-sm="12"
        >
          <img class="popupImage" :src="popupImage" alt="" />
        </vs-col>
        <vs-col class="p-1" vs-lg="4" vs-sm="12">
          <vs-row vs-type="flex" vs-justify="center" vs-w="12">
            <popupProductDetail
              :title="popupTitle"
              :resinGr="orderItem.product.resin_gr"
              :stoneCount="orderItem.product.stone_count"
            />
          </vs-row>
          <vs-row
            v-if="orderItem.size != 'General'"
            vs-type="flex"
            vs-justify="center"
            vs-w="12"
          >
            <vx-card class="cardHeight">
              <vs-row
                class="mb-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <p class="popupTitle">
                  {{ $t("Quantity") }} : {{ orderItem.quantity }}
                </p>
              </vs-row>

              <vs-row
                class="mb-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <p
                  v-if="orderItem.size != 'General'"
                  class="popupSelectedSizeTitle mr-1"
                >
                  {{ $t("Selected_Size") }}
                </p>
              </vs-row>

              <vs-row
                class="mb-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                :key="randomKey(size)"
                v-for="(size, index) in orderItem.selectedSize"
              >
                <p
                  class="popupTitle"
                  v-if="
                    orderItem.size != 'General' &&
                    orderItem.selectedSize[index] != 0
                  "
                >
                  {{ $t("Size") }} {{ 16 + index * 2 }} :
                  {{ orderItem.selectedSize[index] }}
                </p>
              </vs-row>
            </vx-card>
          </vs-row>
        </vs-col>
      </vs-row>
    </vs-popup>
    <img
      v-if="!imageErrorChangePhoto"
      class="itemPhoto mx-auto block"
      color="#ffffff"
      :src="orderItem.product.thumbnail"
      @click="
        openPopupProductImage(orderItem.product.title, orderItem.product.image)
      "
      @error="imageError()"
      @load="handleLoad"
    />
    <img
      class="itemPhoto mx-auto block"
      v-else
      src="@/assets/images/pages/no_image.png"
      alt=""
    />

    <vs-divider class="m-0" />
    <p class="cardTittle text-center">{{ $t("Product_ID") }}</p>
    <p class="productTitle text-center">{{ orderItem.product.title }}</p>

    <vs-divider class="m-0" />
    <p class="cardTittle text-center">{{ $t("Quantity") }}</p>

    <p class="text-center">{{ orderItem.quantity }}</p>
  </div>
</template>

<script>
import popupProductDetail from "./PopupProductDetail";

export default {
  data() {
    return {
      imageErrorChangePhoto: false,

      //Popup
      popupTitle: "",
      popupActive: false,
      popupImage: null,
    };
  },
  props: ["orderItem"],
  components: {
    popupProductDetail,
  },

  methods: {
    randomKey() {
      return Math.random() * Math.random() + 5;
    },
    openPopupProductImage(title, image) {
      this.popupTitle = title;
      this.popupImage = image;
      this.popupActive = true;
    },
    imageError() {
      this.imageErrorChangePhoto = true;
      this.$emit("loadedImage");
      return true;
    },
    handleLoad() {
      this.$emit("loadedImage");
    },
  },
};
</script>

<style lang="scss">
.con-vs-popup.fullscreen .vs-popup {
  width: auto !important;
  height: auto !important;
  .popupImage {
    width: 100%;
    height: auto;
  }
}
</style>

<style lang="scss" scoped>
.vx-card .vx-card__collapsible-content img {
  display: block;
  width: 100%;
}
.cardHeight {
  height: max-content;
}
.popupSelectedSizeTitle {
  font-weight: 700;
  font-size: 16px;
}
.popupTitle {
  font-weight: 700;
}
.cardTittle {
  font-weight: 700;
}

.productTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemPhoto {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.detailProduct {
  width: 100%;
  border: 1px solid #efefef;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
</style>
